import React from "react";

function PomodoroSessionLength(props) {
  function decreaseSession() {
    if (props.sessionLength === 1) {
      return;
    }
    props.decreasePomodoroSession();
  }
  function increaseSession() {
    if (props.sessionLength === 99) {
      return;
    }
    props.increasePomodoroSession();
  }
  return (
    <section>
      <h3 className="text-sm font-semibold -mb-1 pt-1 mt-2 dark:text-gray-200">
        Pomodoro Length
      </h3>
      <div className="flex flex-row justify-center items-center">
        <button onClick={decreaseSession} className="pomodoro-buttons">
          -
        </button>
        <p className="text-5xl font-bold text-gray-700 mx-3 dark:text-gray-50">
          <span>
            {props.sessionLength === 0
              ? "00"
              : props.sessionLength < 10
              ? "0" + props.sessionLength
              : props.sessionLength}
          </span>
          {/* {props.sessionLength} */}
          <span>:00</span>
        </p>
        <button onClick={increaseSession} className="pomodoro-buttons">
          +
        </button>
      </div>
    </section>
  );
}

export default PomodoroSessionLength;
