import React from "react";

function PomodoroShortBreakInterval(props) {
  function decreaseCounter() {
    if (props.shortBreakInterval === 1) {
      return;
    }
    props.decreaseShortBreak();
  }
  function increaseCounter() {
    if (props.shortBreakInterval === 60) {
      return;
    }
    props.increaseShortBreak();
  }
  return (
    <section>
      {/* Move this to component in the pomodoro application component  */}
      <h3 className="text-sm font-semibold -mb-1 pt-1 dark:text-gray-200">Short Break Length</h3>
      <div className="flex flex-row justify-center items-center">
        <button onClick={decreaseCounter} className="pomodoro-buttons">
          -
        </button>
        <p className="text-5xl font-bold text-gray-700 dark:text-gray-50 mx-3 ">
          <span>
            {props.shortBreakInterval === 0
              ? "00"
              : props.shortBreakInterval < 10
              ? "0" + props.shortBreakInterval
              : props.shortBreakInterval}
          </span>
          {/* {props.shortBreakInterval} */}
          <span>:00</span>
        </p>
        <button onClick={increaseCounter} className="pomodoro-buttons">
          +
        </button>
      </div>
    </section>
  );
}

export default PomodoroShortBreakInterval;
