import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import setAxiosHeaders from "./AxiosHeaders";
import Spinner from "./Spinner";

//Import components
import TodoItems from "./TodoItems";
import TodoItem from "./TodoItem";
import TodoForm from "./TodoForm";
import PomodoroApp from "./PomodoroApp";

class TodoApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todoItems: [],
      hideCompletedTodoItems: false,
      isLoading: true,
    };
    this.getTodoItems = this.getTodoItems.bind(this);
    this.createTodoItem = this.createTodoItem.bind(this);
    this.toggleCompletedTodoItems = this.toggleCompletedTodoItems.bind(this);
    // this.deleteCompletedTodoItems = this.deleteCompletedTodoItems.bind(this);
    // this.deleteTodoItems = this.deleteTodoItems.bind(this);
  }
  createTodoItem(todoItem) {
    const todoItems = [todoItem, ...this.state.todoItems];
    this.setState({ todoItems });
  }
  toggleCompletedTodoItems() {
    this.setState(
      {
        hideCompletedTodoItems: !this.state.hideCompletedTodoItems,
      },
      console.log(this.state.hideCompletedTodoItems)
    );
  }

  deleteCompletedTodoItems() {
    // if (this.state.todoItems.complete) {
    console.log(this.state.todoItems.map);
    // }
  }

  deleteTodoItems() {
    this.setState({ todoItems: [] });
    // this.handleDestroyAll(this.state.todoItems);
  }
  componentDidMount() {
    this.getTodoItems();
  }
  getTodoItems() {
    axios
      .get("/api/v1/todo_items")
      .then((response) => {
        this.setState({ isLoading: true });
        const todoItems = response.data;
        this.setState({ todoItems });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: true });
        console.log(error);
      });
  }
  render() {
    return (
      <>
        <div className="shadow p-5 bg-white dark:bg-dark-secondary-bg rounded-md">
          {!this.state.isLoading && (
            <>
              <PomodoroApp />
              <TodoForm
                createTodoItem={this.createTodoItem}
                // For eye
                // hideCompletedTodoItems={this.state.hideCompletedTodoItems}
              />
              <TodoItems
                toggleCompletedTodoItems={this.toggleCompletedTodoItems}
                hideCompletedTodoItems={this.state.hideCompletedTodoItems}
                // deleteCompletedTodoItems={this.deleteCompletedTodoItems}
                // deleteTodoItems={this.deleteTodoItems}
              >
                {this.state.todoItems.map((todoItem) => (
                  <TodoItem
                    key={todoItem.id}
                    todoItem={todoItem}
                    getTodoItems={this.getTodoItems}
                    // For eye
                    hideCompletedTodoItems={this.state.hideCompletedTodoItems}
                    // For Other buttons
                    // deleteCompletedTodoItems={this.deleteCompletedTodoItems}
                    // completedTodoItems={this.state.todoItems.complete}
                    // deleteTodoItems={this.deleteTodoItems}
                  />
                ))}
              </TodoItems>
              {/* <Spinner /> */}
            </>
          )}
          {this.state.isLoading && <Spinner />}
        </div>
      </>
    );
  }
}

document.addEventListener("turbolinks:load", () => {
  const app = document.getElementById("todo-app");
  app && ReactDOM.render(<TodoApp />, app);
});
