import React from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import axios from "axios";
import setAxiosHeaders from "./AxiosHeaders";

class TodoItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: this.props.todoItem.complete,
    };
    this.handleDestroy = this.handleDestroy.bind(this);
    this.path = `/api/v1/todo_items/${this.props.todoItem.id}`;
    this.handleChange = this.handleChange.bind(this);
    this.updateTodoItem = this.updateTodoItem.bind(this);
    this.inputRef = React.createRef();
    this.completedRef = React.createRef();
  }
  handleChange() {
    this.setState({
      complete: this.completedRef.current.checked,
    });
    this.updateTodoItem();
  }
  updateTodoItem = _.debounce(() => {
    setAxiosHeaders();
    axios
      .put(this.path, {
        todo_item: {
          title: this.inputRef.current.value,
          complete: this.completedRef.current.checked,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }, 1000);
  updateTodoItem() {
    this.setState({ complete: this.completedRef.current.checked });
    setAxiosHeaders();
    axios
      .put(this.path, {
        todo_item: {
          title: this.inputRef.current.value,
          complete: this.completedRef.current.checked,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }
  handleDestroy() {
    setAxiosHeaders();
    // const confirmation = confirm("Are you sure?");
    // if (confirmation) {
    axios

      .delete(this.path)
      .then((response) => {
        this.props.getTodoItems();
        console.log(this.path);
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  }
  render() {
    const { todoItem } = this.props;
    return (
      <tr
        className={`${
          this.state.complete && this.props.hideCompletedTodoItems
            ? `hidden`
            : ""
        } ${this.state.complete ? "" : ""}
        
        `}
      >
        <td className="w-1/12">
          <div className="flex items-center justify-end">
            <input
              type="boolean"
              defaultChecked={this.state.complete}
              type="checkbox"
              onChange={this.handleChange}
              ref={this.completedRef}
              className="appearance-none dark:bg-dark-tertiary-bg checked:bg-gray-300 checked:border-transparent checked:border-0 focus:ring-0 rounded-full hover:bg-gray-200 dark:hover:bg-dark-secondary-bg w-6 h-6 cursor-pointer text-gray-400 dark:text-dark-tertiary-bg mr-2 shadow-inner"
            />
          </div>
        </td>

        <td className="w-10/12 ">
          <input
            type="text"
            defaultValue={todoItem.title}
            disabled={this.state.complete}
            onChange={this.handleChange}
            ref={this.inputRef}
            className={`form-input-todo truncate ${
              this.state.complete && this.props.hideCompletedTodoItems
                ? `hidden`
                : ""
            } ${
              this.state.complete
                ? "line-through text-gray-400 dark:text-gray-300"
                : ""
            }`}
            id={`todoItem__title-${todoItem.id}`}
          />
        </td>
        <td className="w-1/12">
          <div className="flex items-center text-left ml-2">
            <button
              onClick={this.handleDestroy}
              className="inline-flex bg-transparent rounded-md p-1.5 text-gray-600 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-dark-tertiary-bg focus:outline-none focus:ring-0 focus:ring-inset active:bg-gray-200 dark:active:bg-dark-background active:shadow-inner"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-5 w-5 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </td>
        <td>
          {/* Dot */}
          {/* <div
            class={`rounded-full bg-gray-100 w-2 h-2${
              this.state.complete && this.props.hideCompletedTodoItems
                ? `hidden`
                : ""
            } ${this.state.complete ? "bg-green-300" : "bg-gray-600"}`}
          ></div> */}
        </td>
      </tr>
    );
  }
}

export default TodoItem;

TodoItem.propTypes = {
  todoItem: PropTypes.object.isRequired,
  getTodoItems: PropTypes.func.isRequired,
  hideCompletedTodoItems: PropTypes.bool.isRequired,
};
