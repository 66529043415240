import React from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

class TodoItems extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    // new buttons
    // this.handleDeleteCompleted = this.handleDeleteCompleted.bind(this);
    // this.handleDeleteAll = this.handleDeleteAll.bind(this);
  }
  handleClick() {
    this.props.toggleCompletedTodoItems();
  }
  // New buttons
  // handleDeleteCompleted() {
  //   this.props.deleteCompletedTodoItems();
  // }
  // handleDeleteAll() {
  //   this.props.deleteTodoItems();
  // }
  // removeAllCompleted() {
  //   console.log(this.props.children);
  // }
  render() {
    return (
      <>
        <div className="flex flex-row">
          <div className="w-11/12"></div>
          <div className="w-1/12">
            {/* Hide Or View Todos button (eye) */}
            <div className=" flex justify-left text-left text-gray-700 dark:text-gray-50 -ml-0.5 -mt-9 2xl:ml-1">
              <button className="app-button" onClick={this.handleClick}>
                <Tippy
                  className="mb-2"
                  content={<span>Hide/Show completed tasks</span>}
                >
                  {this.props.hideCompletedTodoItems ? (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    </div>
                  )}
                </Tippy>
              </button>

              {/* Destroy All COMPLETED button (X) */}
              {/* <button className="app-button" onClick={this.handleDeleteCompleted}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </button> */}

              {/* Destroy All button (eye) */}

              {/* <button className="app-button" onClick={this.handleDeleteAll}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </div>
          </button>

          <button className="app-button" onClick={this.removeAllCompleted}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5 bg-red-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </div>
          </button> */}
            </div>
          </div>
        </div>

        {/* down here, I can put different h-#v depending on the screen size for responsiveness */}
        {/* <hr className="mt-1 sm:ml-2.5 md:ml-5 lg:ml-10 xl:ml-0" /> */}
        <div className="overflow-y-auto h-50v mt-1">
          <div>
            <table className="table-fixed">
              <tbody>{this.props.children}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
export default TodoItems;

TodoItems.propTypes = {
  toggleCompletedTodoItems: PropTypes.func.isRequired,
  hideCompletedTodoItems: PropTypes.bool.isRequired,
  // deleteCompletedTodoItems: PropTypes.func.isRequired,
  // deleteTodoItems: PropTypes.func.isRequired,
};
