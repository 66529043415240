import React from "react";
import PropTypes from "prop-types";

import axios from "axios";
import setAxiosHeaders from "./AxiosHeaders";

class TodoForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.titleRef = React.createRef();
  }

  handleSubmit(e) {
    e.preventDefault();
    setAxiosHeaders();
    axios
      .post("/api/v1/todo_items", {
        todo_item: {
          title: this.titleRef.current.value,
          complete: false,
        },
      })
      .then((response) => {
        const todoItem = response.data;
        this.props.createTodoItem(todoItem);
      })
      .catch((error) => {
        console.log(error);
      });
    e.target.reset();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="">
        <div className="flex items-center">
          <div className="w-8/12">
            <input
              type="text"
              name="title"
              ref={this.titleRef}
              required
              className="block w-full bg-gray-50 dark:bg-dark-tertiary-bg focus:bg-gray-100 dark:focus:bg-dark-tertiary-bg focus:ring-0 focus:ring-gray-200 border-none shadow-inner focus:border-gray-200 dark:focus:border-dark-background border-0 border-transparent rounded-md text-gray-hybrid dark:text-gray-50 sm:ml-2.5 md:ml-5 lg:ml-10 xl:ml-0"
              id="title"
              placeholder="Add new task here..."
              autoComplete="off"
            />
          </div>
          <div className="w-3/12 flex justify-center">
            <button className="app-button-todo">Add Task</button>
          </div>
          <div className="w-1/12"></div>
        </div>
      </form>
    );
  }
}


export default TodoForm;

TodoForm.propTypes = {
  createTodoItem: PropTypes.func.isRequired,
};
