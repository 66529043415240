import React from "react";
// import ReactDOM from "react-dom";

//components
import PomodoroShortBreakInterval from "./PomodoroShortBreakInterval";
// import PomodoroLongBreakInterval from "./PomodoroLongBreakInterval";
import PomodoroSessionLength from "./PomodoroSessionLength";
import PomodoroTimer from "./PomodoroTimer";

class PomodoroApp extends React.Component {
  constructor() {
    super();
    this.state = {
      isSession: true,
      shortBreakLength: 5,
      longBreakLength: 15,
      sessionLength: 25,
      timerMinute: 25,
      isPlay: false,
    };

    this.onDecreaseShortBreakLength = this.onDecreaseShortBreakLength.bind(
      this
    );

    this.onIncreaseShortBreakLength = this.onIncreaseShortBreakLength.bind(
      this
    );
    this.onDecreasePomodoroSessionLength = this.onDecreasePomodoroSessionLength.bind(
      this
    );
    this.onIncreasePomodoroSessionLength = this.onIncreasePomodoroSessionLength.bind(
      this
    );
    this.onUpdateTimerMinute = this.onUpdateTimerMinute.bind(this);
    this.onToggleInterval = this.onToggleInterval.bind(this);
    this.onResetTimer = this.onResetTimer.bind(this);
    this.onPlayStopTimer = this.onPlayStopTimer.bind(this);
    // this.isSession = this.isSession.bind(this);
  }

  //Increase & Decrease Short break duration functions
  onDecreaseShortBreakLength() {
    this.setState((prevState) => {
      return {
        shortBreakLength: prevState.shortBreakLength - 1,
      };
    });
  }

  onIncreaseShortBreakLength() {
    this.setState((prevState) => {
      return {
        shortBreakLength: prevState.shortBreakLength + 1,
      };
    });
  }

  //Increase & Decrease Pomodoro duration functions
  onDecreasePomodoroSessionLength() {
    this.setState((prevState) => {
      return {
        sessionLength: prevState.sessionLength - 1,
        timerMinute: prevState.sessionLength - 1,
      };
    });
  }

  onIncreasePomodoroSessionLength() {
    this.setState((prevState) => {
      return {
        sessionLength: prevState.sessionLength + 1,
        timerMinute: prevState.sessionLength + 1,
      };
    });
  }

  // Timer
  onUpdateTimerMinute() {
    this.setState((prevState) => {
      return {
        timerMinute: prevState.timerMinute - 1,
      };
    });
  }

  onToggleInterval(isSession) {
    if (isSession) {
      this.setState({
        timerMinute: this.state.sessionLength,
      });
    } else if (!isSession) {
      this.setState({
        timerMinute: this.state.shortBreakLength,
      });
    }
  }

  onResetTimer() {
    this.setState({
      timerMinute: this.state.sessionLength,
    });
  }

  onPlayStopTimer(isPlay) {
    this.setState({
      isPlay: isPlay,
    });
  }

  render() {
    return (
      <div className="flex flex-col justify-center items-center text-center">
        {/* <h2 className="font-bold text-lg">Pomodoro Clock</h2> */}

        <div className={`${this.state.isPlay ? `hidden` : ""}`}>
          <PomodoroShortBreakInterval
            isPlay={this.state.isPlay}
            shortBreakInterval={this.state.shortBreakLength}
            decreaseShortBreak={this.onDecreaseShortBreakLength}
            increaseShortBreak={this.onIncreaseShortBreakLength}
            onPlayStopTimer={this.onPlayStopTimer}
          />
        </div>
        {/* <PomodoroLongBreakInterval
          longBreakInterval={this.state.longBreakLength}
        /> */}
        <div className={`${this.state.isPlay ? `hidden` : ""}`}>
          <PomodoroSessionLength
            isPlay={this.state.isPlay}
            sessionLength={this.state.sessionLength}
            decreasePomodoroSession={this.onDecreasePomodoroSessionLength}
            increasePomodoroSession={this.onIncreasePomodoroSessionLength}
            onPlayStopTimer={this.onPlayStopTimer}
          />
        </div>

        <PomodoroTimer
          isPlay={this.state.isPlay}
          timerMinute={this.state.timerMinute}
          breakLength={this.state.shortBreakLength}
          updateTimerMinute={this.onUpdateTimerMinute}
          toggleInterval={this.onToggleInterval}
          resetTimer={this.onResetTimer}
          onPlayStopTimer={this.onPlayStopTimer}
          //   isSession={this.isSession}
        />
      </div>
    );
  }
}

export default PomodoroApp;
