import React from "react";
import ReactDOM from "react-dom";
// import { createPopper } from "@popperjs/core";
import ThemeToggle from "./ThemeToggle";

class NavBar extends React.Component {
  constructor() {
    super();
    this.state = {
      showHideMenu: false,
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    switch (name) {
      case "showHideMenu":
        this.setState({ showHideMenu: !this.state.showHideMenu });
        break;

      default:
        null;
    }
  }
  render() {
    const { showHideMenu } = this.state;
    return (
      <div>
        <nav>
          <div className="max-w-8xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:block sm:ml-6"></div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Dark Mode Toggle */}
                {/* <ThemeToggle /> */}
                <div className="ml-8 relative">
                  <div>
                    <button
                      type="button"
                      className="app-button text-gray-800 dark:text-gray-50 transition duration-150 ease-in-out transform hover:rotate-90 "
                      id="user-menu"
                      aria-expanded="false"
                      aria-haspopup="true"
                      onClick={() => this.hideComponent("showHideMenu")}
                    >
                      {/* 3 Dots */}
                      {/* <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                        />
                      </svg> */}

                      {/* Cog */}
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </button>
                  </div>
                  {/* Menu */}
                  {showHideMenu && (
                    <div
                      className="z-30 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-dark-secondary-bg ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                      id="menu"
                    >
                      <a
                        href="/users/edit"
                        className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-50 dark:hover:bg-dark-tertiary-bg"
                        role="menuitem"
                      >
                        Profile Settings
                      </a>

                      {/* <a
                        href="#"
                        className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Pomodoro Settings
                      </a> */}

                      <a
                        data-method="delete"
                        href="/users/sign_out"
                        rel="nofollow"
                        className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-50 dark:hover:bg-dark-tertiary-bg"
                        role="menuitem"
                      >
                        Sign out
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

document.addEventListener("turbolinks:load", () => {
  const app = document.getElementById("nav-bar");
  app && ReactDOM.render(<NavBar />, app);
});
