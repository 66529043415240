import React, { Component } from "react";
// import useSound from "use-sound";
import logo from "../images/logotest.png";
// import BreakFinishedSound from "https://assets.mixkit.co/sfx/preview/mixkit-arcade-game-complete-or-approved-mission-205.mp3";
// import PomodoroFinishedSound from "../sounds/pomodoro-finished.mp3";

function playAudio(url) {
  new Audio(url).play();
}

class Timer extends React.Component {
  constructor() {
    super();
    this.state = {
      isSession: true,
      timerSecond: 0,
      intervalId: 0,
    };

    //Binds
    this.playTimer = this.playTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.decreaseTimer = this.decreaseTimer.bind(this);

    // Music & SFX
    this.startSwitchSoundUrl =
      "https://assets.mixkit.co/sfx/preview/mixkit-on-or-off-light-switch-tap-2585.mp3";
    this.startSwitchSound = new Audio(this.startSwitchSoundUrl);

    this.stopSwitchSoundUrl =
      "https://assets.mixkit.co/sfx/preview/mixkit-tape-measure-extend-810.mp3";
    this.stopSwitchSound = new Audio(this.stopSwitchSoundUrl);

    this.pomodoroFinishedSoundUrl =
      "https://assets.mixkit.co/sfx/preview/mixkit-arcade-game-complete-or-approved-mission-205.mp3";
    this.pomodoroFinishedSound = new Audio(this.pomodoroFinishedSoundUrl);

    this.breakFinishedSoundUrl =
      "https://assets.mixkit.co/sfx/preview/mixkit-unlock-game-notification-253.mp3";
    this.breakFinishedSound = new Audio(this.breakFinishedSoundUrl);
  }
  //   buttons

  playTimer() {
    let intervalId = setInterval(this.decreaseTimer, 1000);
    this.props.onPlayStopTimer(true);
    this.setState({ intervalId: intervalId });
    playAudio(
      "https://assets.mixkit.co/sfx/preview/mixkit-on-or-off-light-switch-tap-2585.mp3"
    );
  }

  stopTimer() {
    clearInterval(this.state.intervalId);
    // this.props.onPlayStopTimer(false);
    playAudio(
      "https://assets.mixkit.co/sfx/preview/mixkit-tape-measure-extend-810.mp3"
    );
  }

  resetTimer() {
    this.stopTimer();
    this.props.resetTimer();
    this.props.onPlayStopTimer(false);
    this.setState({ timerSecond: 0, isSession: true });
  }

  // Timer functions
  decreaseTimer() {
    switch (this.state.timerSecond) {
      case 0:
        if (this.props.timerMinute === 0) {
          playAudio(
            "https://assets.mixkit.co/sfx/preview/mixkit-unlock-game-notification-253.mp3"
          );
          if (this.state.isSession) {
            playAudio(
              "https://assets.mixkit.co/sfx/preview/mixkit-arcade-game-complete-or-approved-mission-205.mp3"
            );
            this.setState({
              isSession: false,
            });

            this.props.toggleInterval(this.state.isSession);
          } else if (!this.state.isSession) {
            this.setState({
              isSession: true,
            });

            this.props.toggleInterval(this.state.isSession);
          }
        } else {
          this.props.updateTimerMinute();
          this.setState({
            timerSecond: 59,
          });
        }

        break;
      default:
        this.setState((prevState) => {
          return {
            timerSecond: prevState.timerSecond - 1,
          };
        });
        break;
    }
  }
  //Audio
  // playAudio(url) {
  //   new Audio(url).play();
  // }
  // Timer component
  render() {
    // Sounds
    // const [play] = useSound(this.breakFinishedSound);

    return (
      <section>
        <section>
          <h3
            className={`text-sm font-semibold ${
              this.props.isPlay ? "" : "hidden"
            }`}
          >
            {this.state.isSession === true ? (
              <section className="flex-row space-x-3 mt-3 dark:text-gray-50">
                <button className="rounded-md bg-pomodoro-session-type-active-bg dark:bg-dark-background px-2 py-0.5 cursor-default focus:outline-none focus:ring-offset-0 focus:ring-transparent font-semibold text-base">
                  Pomodoro
                </button>
                <button className="bg-transparent px-2 py-0.5 cursor-default focus:outline-none focus:ring-offset-0 focus:ring-transparent font-semibold text-base">
                  Short Break
                </button>
              </section>
            ) : (
              <section className="flex-row space-x-3 mt-3 dark:text-gray-50">
                <button className="bg-transparent px-2 py-0.5 cursor-default focus:outline-none focus:ring-offset-0 focus:ring-transparent font-semibold text-base">
                  Pomodoro
                </button>
                <button className="rounded-md bg-pomodoro-session-type-active-bg dark:bg-dark-background px-2 py-0.5 cursor-default focus:outline-none focus:ring-offset-0 focus:ring-transparent font-semibold text-base">
                  Short Break
                </button>
              </section>
            )}
          </h3>
          <p
            className={`text-8xl font-bold text-gray-700 dark:text-gray-50 mx-3 mt-2 ${
              this.props.isPlay ? "" : "hidden"
            }`}
          >
            <span>{this.props.timerMinute}</span>
            <span>:</span>
            <span>
              {this.props.timerSecond === 0
                ? "00"
                : this.state.timerSecond < 10
                ? "0" + this.state.timerSecond
                : this.state.timerSecond}
            </span>
          </p>
          <section className="flex justify-center space-x-2 my-4">
            <div className={`${this.props.isPlay ? `hidden` : ""}`}>
              <button onClick={this.playTimer} className="pomodoro-action">
                Start Timer
              </button>
            </div>
            {/* Hidden & hidden here, to force people to use the pomodoro more */}
            <div className={`${this.props.isPlay ? "hidden" : `hidden`}`}>
              <button onClick={this.stopTimer} className="pomodoro-action">
                Stop
              </button>
            </div>
            <div className={`${this.props.isPlay ? "" : `hidden`}`}>
              <button onClick={this.resetTimer} className="pomodoro-action">
                Reset Timer
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

export default Timer;
