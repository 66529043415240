import React from "react";
import ReactDOM from "react-dom";
// import Player from "./PlayerAppPlayer";
// import Song from "./PlayerAppSong";

import vynilImage from "../images/vynil.png";
import { PlayIcon, PauseIcon } from "@heroicons/react/solid";

class PlayerApp extends React.Component {
  constructor() {
    super();
    this.state = {
      playing: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({
      playing: !this.state.playing,
    });
    console.log(this.state.playing);
  }
  render() {
    return (
      <div className="App xl:flex justify-center relative hidden xl:mt-0">
        <div className="z-10 absolute h-full w-full flex justify-center items-center">
          <button onClick={this.handleClick} className="app-button">
            {this.state.playing ? (
              <PauseIcon className="h-20 w-20 text-gray-50 dark:text-dark-background" />
            ) : (
              <PlayIcon className="h-20 w-20 text-gray-50 dark:text-dark-background" />
            )}
          </button>
        </div>

        <div className="wheel ">
          {/* flex justify-center items-center overflow-hidden ⏫ */}
          <div>
            <iframe
              width="0"
              height="0"
              src={`${
                this.state.playing
                  ? `https://www.youtube.com/embed/5yx6BWlEVcY?autoplay=1`
                  : "https://www.youtube.com/embed/5yx6BWlEVcY"
              }`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
          <img
            src={vynilImage}
            onClick={this.handleClick}
            alt="Vinyl "
            className={`animate-spin-slow ${
              this.state.playing ? `` : "paused-animation"
            }`}
          />
        </div>
      </div>
    );
  }
}

document.addEventListener("turbolinks:load", () => {
  const app = document.getElementById("player-app");
  app && ReactDOM.render(<PlayerApp />, app);
});
